import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/styles.css'
import App from './App';
import { CookiesProvider } from 'react-cookie';
import { StyledEngineProvider } from '@mui/material/styles';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StyledEngineProvider injectFirst>
  <CookiesProvider>
  <React.StrictMode>
  <I18nextProvider i18n={i18n}>
    <App />
    </I18nextProvider>
  </React.StrictMode>
  </CookiesProvider>
  </StyledEngineProvider>

);
