import React, { useEffect, useState } from 'react';
import '../styles/UserLearnedInformation.css';

const DebugInformation = ({ debug_info, sessionNumber}) => {
    const [problemClassification, setProblemClassification] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const API_BASE_URL = window.location.origin === "http://localhost:3000"  ? "http://localhost:8000"  : process.env.REACT_APP_API_BASE_URL;


    useEffect(() => {
        fetch(API_BASE_URL + '/get_last_classification', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            params: { sessionNumber }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            }
            )
            .then(data => {
                setProblemClassification(data);
                setLoading(false);

            }
            )
            .catch(error => {
                setError(error);
            }
            );


    }, [API_BASE_URL]);

    if (loading) return <p className="loading">Loading...</p>;
    if (error) return <p className="error">Error loading data: {error.message}</p>;
    if (!problemClassification.classification) return (
            <div>
            <p className="error">No data found</p>
            <div className="button-container">
                <button className="ok-button" onClick={() => debug_info()}>OK</button>
            </div>
            </div>
            );

    
    return (
        <div className="table-container">

            <h2>Problem Classification to match the RAG</h2>
            <table className="table">
                <thead>
                    <tr>
                        <th>Time</th>
                        <th>Classification</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>{problemClassification.classification.time}</td>
                    <td>{problemClassification.classification.description}</td>
                    </tr>
                </tbody>
            </table>
            <br></br> <br></br> <br></br>
            <h2>Classification Embeddings</h2>
            <table className="table">
                <thead>
                    <tr>
                        <th>Score</th>
                        <th>Text</th>
                    </tr>
                </thead>
                <tbody>
                {problemClassification.classification.similar_text.matches.map((match, index) => (
                    <tr key={index}>
                        <td>{match.embedding_score}</td>
                        <td>{match.full_text}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <br></br> <br></br> <br></br>

            <div className="button-container">
                <button className="ok-button" onClick={() => debug_info()}>OK</button>
            </div>
        </div>
    );
};

export default DebugInformation;