import React from 'react';
import { StyleSheet, Text, View, Dimensions, Animated } from 'react-native';
import {  useEffect, useRef } from 'react';

const { width } = Dimensions.get('window');
const isMobile = width < 768; // Example threshold for mobile detection


const styles = StyleSheet.create({
  progressPath: {
    borderLeft: "1px solid #6c757d"
  },
  stepContainer: {
    flexDirection: "row",
    minHeight: 60,
    marginBottom: 10,
  },
  stepLine: {
    width: 1.5,
    height: "120%",
    backgroundColor: "#6c757d",
    zIndex: 1,
    position: "absolute",
    left: 18
  },
  stepIndicator: {
    position: "relative",
    width: 36, // reduced width
    justifyContent: "flex-start",
    alignItems: "center",
    aspectRatio: "1/1",
    paddingTop: 18,
    borderRadius: 18, // make the circles fully rounded
  },
  stepIndicatorText: {
    textAlign: "right",
    justifyContent: "center",
    alignItems: "center",
    aspectRatio: "1/1",
    borderRadius: "50%",
    padding: 5, // reduce padding slightly
    backgroundColor: "#d3d3d3", // default light gray
    color: "#333333",
    zIndex: 1,
    fontSize: 16 // reduce font size
  },
  stepIndicatorTextCompleted: {
    backgroundColor: "#81c784", // softer green
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 12 // smaller font size for the checkmark
  },
  step: {
    flex: 1,
    padding: 15,
    backgroundColor: "#f0f0f0",
    justifyContent: "center",
    borderRadius: 5, // slight rounding of corners
  },
  stepCompleted: {
    backgroundColor: "#e6f7e6" // light green background
  }
});


function VerticalProgressBar({ steps }) {


     
  return (
    <View style={styles.progressPath}>
      {steps.map((step, i) => (
        <>
          <View style={styles.stepContainer} key={i}>
            <View style={styles.stepIndicator}>
              {i < steps.length - 1 ? (
                <View style={styles.stepLine}></View>
              ) : null}
              <Text
                style={[
                  styles.stepIndicatorText,
                  step.completed && styles.stepIndicatorTextCompleted
                ]}
              >
                {step.completed ? "✔️" : i + 1} {/* Show checkmark for completed */}
              </Text>
            </View>
            <View style={[styles.step, step.completed && styles.stepCompleted]}>
              <Text>{step.name}</Text>
            </View>
          </View>
          <View style={styles.divider}></View>
        </>
      ))}
    </View>

  );
}

export default VerticalProgressBar;