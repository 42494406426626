// src/components/Header.js
import React, { useState, useEffect, useRef } from 'react';
import { googleLogout } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import { useLanguage } from './LanguageContext';
import '../styles/Header.css';
import ClearModal from './ClearModal';
import { use } from 'i18next';

const Header = ({ google_client_id, logout, setChatLanguage, user_learn_info, loadSession, debug_info, showTalkLegendModal, resetMessages }) => {
  const { t, i18n } = useTranslation();
  const { language, changeLanguage } = useLanguage();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isCleanAllModalOpen, setCleanAllModalOpen] = useState(false);
  const [isClearModalOpen, setClearModalOpen] = useState(false);

  const API_BASE_URL = window.location.origin === "http://localhost:3000"  ? "http://localhost:8000"  : process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    let timer;
    if (dropdownOpen) {
      timer = setTimeout(() => setDropdownOpen(false), 5000);
    }
    return () => clearTimeout(timer);
  }, [dropdownOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
        setLanguageMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const mylogout = () => {
    console.log('Logging out');
    googleLogout();
    logout();
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    setLanguageMenuOpen(false);
  };

  const toggleLanguageMenu = () => {
    setLanguageMenuOpen(!languageMenuOpen);
  };

  const handleLanguageChange = (langCode) => {
    changeLanguage(langCode);
    i18n.changeLanguage(langCode);
    setChatLanguage(langCode);
  };

  const commmands = () => {
    console.log('Commands');
    showTalkLegendModal();
  }

  const clearMessages = () => {
    setClearModalOpen(true);
  };

  const handleClearMessages = () => {
    setClearModalOpen(false);
    resetMessages();
  };

  const cleanAll = () => {
    console.log('Clean All');
    setCleanAllModalOpen(true);
    
  }
  async function cleanAllApproved() {
    console.log('Clean All Approved');
    setCleanAllModalOpen(false);
    // Call server delete_user_all
    const response = await fetch(API_BASE_URL + '/delete_user_all', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
    });
    if (!response.ok) {
      console.error('Failed to delete all  sessions');
      return;
    }
    resetMessages();
  }

  const languages = [
    { code: 'en-US', name: t('header.languages.en-US') },
    { code: 'es-ES', name: t('header.languages.es-ES') },
    { code: 'fr-FR', name: t('header.languages.fr-FR') },
    { code: 'de-DE', name: t('header.languages.de-DE') },
    { code: 'it-IT', name: t('header.languages.it-IT') },
    { code: 'he-IL', name: t('header.languages.he-IL') }
  ];

  return (
    <header className="chat-header">
      <div className="header-content">
        <div className="headline">
          {t('header.headline')}
        </div>
        <div className="menu-container">
          <button className="menu-button" onClick={toggleDropdown}>
            <div className="menu-icon">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
          {dropdownOpen && (
            <div className="dropdown-menu">
              <button onClick={mylogout}>{t('header.logout')}</button>
              <button onClick={user_learn_info}>{t('header.info')}</button>
              <button onClick={debug_info}>{t('header.debug')}</button>
              <button onClick={clearMessages}>{t('header.clear')}</button>
              <button onClick={toggleLanguageMenu}>
                {t('header.language')}
                {languageMenuOpen && (
                  <div className="submenu">
                    {languages.map((lang) => (
                      <button
                        key={lang.code}
                        onClick={() => handleLanguageChange(lang.code)}
                        className={language === lang.code ? 'selected' : ''}
                      >
                        {lang.name}
                      </button>
                    ))}
                  </div>
                )}
              </button>
              {/* <button onClick={loadSession}>{t('header.load')}</button>
              <button onClick={commmands}>{t('header.commands')}</button> */}
              <button onClick={cleanAll}>{t('header.cleanAll')}</button>
            </div>
          )}

        </div>
         <ClearModal
                  className="clean-modal"
                  isOpen={isCleanAllModalOpen}
                  onClose={() => setCleanAllModalOpen(false)}
                  onConfirm={cleanAllApproved}
                  name="clean-all"
                />
         <ClearModal
                  className="clear-modal"
                  isOpen={isClearModalOpen}
                  onClose={() => setClearModalOpen(false)}
                  onConfirm={handleClearMessages}
                />
      </div>
    </header>
  );
};

export default Header;