import React, { useState, useEffect, useRef, useContext, useCallback   } from 'react';
import '../styles/SessionSidebar.css';
import MenuIcon from '@mui/icons-material/Menu';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TableRowsIcon from '@mui/icons-material/TableRows';
import NoteAlt from '@mui/icons-material/NoteAlt';
import { use } from 'i18next';
import { useTranslation, Trans } from 'react-i18next';
import LanguageContext from './LanguageContext';



function SessionSidebar({setSessionNumber,reloadSidebar}) {
  const [collapsed, setCollapsed] = useState(true);
  const API_BASE_URL = window.location.origin === "http://localhost:3000" ? "http://localhost:8000" : process.env.REACT_APP_API_BASE_URL;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sessions, setSessions] = useState([]);
  const { t } = useTranslation();
  const { language, changeLanguage } = useContext(LanguageContext);

  const createNewSession = async () => {
    console.log('Create new session');
  
    fetch(API_BASE_URL + '/create_new_session', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ session_name: t('sessionsidebar.newSessionName') }),
      credentials: 'include',
  })
      .then(response => {
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          return response.json();
      }
      )
      .then(data => {
          console.log(data.session_id);
          setSessionNumber(data.session_id);
      }
      )
      .catch(error => {
          setError(error);
      }
      );
      setCollapsed(true);
      // add 1 second timeout and then call loadSessions for db to update did not work without timeout
      setTimeout(() => {
        loadSessions();
      }, 1000);
      
  }

  
  const loadSessions = useCallback(async () => {
    try {
        fetch(API_BASE_URL + '/get_user_sessions', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            }
            )
            .then(data => {
                console.log(data);
                setIsLoaded(true);
                setSessions([...data]); // Ensure sessions array is updated immutably
            }
            )
            .catch(error => {
                setError(error);
            }
            );
    } catch (error) {
        console.error('Error loading sessions', error);
    }
  }, [API_BASE_URL]); // Ensure loadSessions does not change on each render

  useEffect(() => {
    loadSessions();
  }, [reloadSidebar]);

  const selectSession = (sessionNumber) => {
    console.log('Select session:', sessionNumber);
    setSessionNumber(sessionNumber);
    setCollapsed(true);
  }

  return (
    <div className={`sessionsidebar ${collapsed ? 'collapsed' : ''}`}>
      <div className="sessionsidebar-header" onClick={() => setCollapsed(!collapsed)} >
        <ViewSidebarIcon className={collapsed ? 'sessionsidebar-header-icon' : '' } style={{ cursor: 'pointer' }} />
        <h2>{!collapsed && t('sessionsidebar.session')}</h2>
      </div>
      {!collapsed && (
        <ul className="sessionsidebar-menu">
          <li className="sessionsidebar-item" onClick={createNewSession}>
            <NoteAlt onClick={createNewSession} />
            <span>{t('sessionsidebar.newSession')}</span>
          </li>
          {error && <li>Error: {error.message}</li>}
          {!isLoaded && <li>Loading...</li>}
          {sessions.map(session => (
            <li className="sessionsidebar-item" key={session.session_num} onClick={() => selectSession(session.session_num)} style={{ cursor: 'pointer' }} >
              <TableRowsIcon />
              <span title={session.session_name}>{session.session_name}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SessionSidebar;