// src/components/ClearModal.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/ClearModal.css';

const ClearModal = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <div className={"modal-background"}>
      <div className={"modal-container"}>
        <p>{t('clearModal.confirmMessage')}</p>
        <div>
          <button className="button confirm-button" onClick={onConfirm}>{t('clearModal.yes')}</button>
          <button className="button cancel-button" onClick={onClose}>{t('clearModal.no')}</button>
        </div>
      </div>
    </div>
  );
};

export default ClearModal;