import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/ChatTalkStatus.css'; // Import the CSS file if using external stylesheet

const ChatTalkStatus = ({status}) => {
    const { t } = useTranslation();
    return (
        status === '' ?  <> </> :
            <div className='chat-talk-status-text'>
                <h2>{t('talkInterface.status.'+status)}</h2>
            </div> 
    );
    };
export default ChatTalkStatus;