import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';

const GoogleLoginButton = ({ onSuccess }) => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
  const API_BASE_URL = window.location.origin === "http://localhost:3000"  ? "http://localhost:8000"  : process.env.REACT_APP_API_BASE_URL;

  const onLoginSuccess = async (response) => {
    console.log('Login Success:', response);
    try {
      const token = response.credential;
      const serverResponse = await axios.post(
        API_BASE_URL+'/auth/callback',
        { token },
        { withCredentials: true }  // Include credentials (cookies) in the request
      );
      onSuccess(serverResponse.data);
      console.log('Server Response:', serverResponse);
    } catch (error) {
      console.error('Error during token validation', error);
    }
  };

  const onFailure = (response) => {
    console.log('Login Failed:', response);
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin
        onSuccess={onLoginSuccess}
        onFailure={onFailure}
        onError={onFailure}
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginButton;