export default function timeWithMilliseconds() {
  const date = new Date();
  const timeString = date.toLocaleTimeString('en-GB', { // Use 'en-GB' for 24-hour format
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Ensure 24-hour format
  });
  const milliseconds = date.getMilliseconds();

  return `${timeString}.${milliseconds.toString().padStart(3, '0')}`;
}