// src/hooks/Recognition.js
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import timeWithMilliseconds from './timeWithMilliseconds';
import { use } from 'i18next';

export function useRecognition ({
    getLanguage,
    voiceRecordingFinish,
    voiceFinalTranscriptUpdate,
    voiceListeningStopped,
    getCurrentText,
    audioSourceRef,
    isPlaying,
    playbackText,
    audioContextRef,
    setListeningState,
    stopAudio,
    swEchoCancellation,
    talkStatusUpdate,
    inFlightRequest,
    submitCount
}) {
    const listening = useRef(false);
    const recognitionRef = useRef(null);
    const pauseTimeoutRef = useRef([]);
    const stoppedPressedRef = useRef(false);
    const recognitionRestart = useRef(false);
    const prevFinalTranscriptCopyRef = useRef('');
    const prevInterimTranscriptCopyRef = useRef('');
    const interimTranscriptNotCleared = useRef('');
    const startingText = useRef('');
    const ignoreTranscriptDuringPlayback = useRef('');
    const isInitialized = useRef(false);
    const stopTimeoutRef = useRef(null);
    const { t } = useTranslation();
    const PAUSE_DELAY = 3000;
    const [isStopping, setIsStopping] = useState(false);
    const mediaStreamRef = useRef(null);
    const noSpeechRestartCount = useRef(0);
    const languageRef = useRef("");
    const ignoreInterimTranscriptAlreadySent = useRef('');
    const [lastSubmitCount, setLastSubmitCount] = useState(0);

    const debug = true;

    useEffect(() => {
        if (submitCount !== lastSubmitCount) {
            setLastSubmitCount(submitCount);
            if (debug) console.log(timeWithMilliseconds(), 'Submit Count Changed. Clearing Transcript...');
            clearTranscript();
        }
    }, [submitCount, lastSubmitCount]);

    const clearTimers = () => {
        if (Array.isArray(pauseTimeoutRef.current)) {
            pauseTimeoutRef.current.forEach(timerId => {
                clearTimeout(timerId);
            });
            pauseTimeoutRef.current = [];
        } else {
            clearTimeout(pauseTimeoutRef.current);
            pauseTimeoutRef.current = [];
        }
    };

    const clearTranscript = () => {
        if (debug) console.log(timeWithMilliseconds(), 'Clearing Transcript... restart recognition');
        if (recognitionRef.current) {
            recognitionRef.current.stop();
            recognitionRestart.current = true;
        }
    };

    const clearLocalTexts = () => {
        prevFinalTranscriptCopyRef.current = '';
        startingText.current = '';
    };
    
    const forceStopRecognition = () => {
        if (recognitionRef.current) {
            recognitionRef.current.abort();
            setIsStopping(false);
            setListeningState(false);
            listening.current = false;
            stoppedPressedRef.current = false;
            clearTimers();
            voiceRecordingFinish();
        }
    };

    // Function to clear the last middle word
    const clearLastMiddleWord = (word, finalTranscript) => {
        var numWords = word.split(' ').length;
        const finalTranscriptArray = finalTranscript.split(' ');
        const lastWord = finalTranscriptArray.pop();
        // check if the last word is a prefix of the word
        if (word.toLowerCase().includes(lastWord.toLowerCase())) {
            for (var i = 0; i < numWords-1; i++) {
                finalTranscriptArray.pop();
            }
            return finalTranscriptArray.join(' ');
        }
        return finalTranscript;
    };


    const initRecognition = () => {
        if (isInitialized.current) {
            return;
        }
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        const recognition = new SpeechRecognition();

        recognition.continuous = true;
        recognition.interimResults = true;
        recognition.lang = getLanguage();
        recognition.maxAlternatives = 1;

        if (debug) console.log(timeWithMilliseconds(), '############# Recognition: initRecognition', recognition);

        recognition.onstart = () => {
            if (debug) console.log(timeWithMilliseconds(), 'Voice recognition onstart activated');
            try {
                listening.current = true;
                setListeningState(true);
                stoppedPressedRef.current = false;
                prevFinalTranscriptCopyRef.current = '';
                ignoreTranscriptDuringPlayback.current = '';
                clearTimers();
            } catch (error) {
                console.error('Error in onstart:', error);
            }
        };

        recognition.onend = () => {
            ignoreInterimTranscriptAlreadySent.current = '';
            if (recognitionRestart.current) {
                recognitionRestart.current = false;
                recognitionRef.current.start();
                return;
            }            
            if (debug) console.log(timeWithMilliseconds(), 'Voice recognition onend deactivated', stoppedPressedRef.current);
            if (!stoppedPressedRef.current) {
                if (debug) console.log(timeWithMilliseconds(), 'Voice recognition onend restarting... !!!!! Do not know why it stopped in the middle of the conversation.', recognitionRef.current);
                recognitionRef.current.start();
                return;
            }
            listening.current = false;
            setListeningState(false);
            if (!stoppedPressedRef.current) {
                voiceRecordingFinish();
            }
            clearTimers();
            setIsStopping(false); // Reset the stopping flag
            clearTimeout(stopTimeoutRef.current); // Clear the force stop timeout
        };

        recognition.onresult = (event) => {
            if (stoppedPressedRef.current || recognitionRestart.current ) return;

            let interimTranscript = '';
            let finalTranscript = '';

            for (let i = event.resultIndex; i < event.results.length; ++i) {
                if (event.results[i].isFinal) {
                    finalTranscript += event.results[i][0].transcript;
                } else {
                    interimTranscript += event.results[i][0].transcript;
                }
            }
            if (finalTranscript === '' && interimTranscript === '') {
                if (debug) console.log(timeWithMilliseconds(), 'Impossible to have both final and interim transcript empty. Ignoring...');
                return;
            }
            if ( interimTranscript !== '') {
                if (debug) console.log(timeWithMilliseconds(), 'Interim Transcript:', interimTranscript);
            }
            if (finalTranscript !== '') {
                if (debug) console.log(timeWithMilliseconds(), 'Final Transcript:', finalTranscript);
            }

            // every time we get a result, we need to clear the timers
            // if needed new timers will be set
            clearTimers();

            let backupInterimTranscript = interimTranscript;
            if (interimTranscriptNotCleared.current !== '') {
                if ( finalTranscript !== '' )
                    interimTranscriptNotCleared.current = '';
                else {
                    if (debug) console.log(timeWithMilliseconds(), 'Ignoring interim transcript interimTranscriptNotCleared: ', interimTranscriptNotCleared.current);
                    if (interimTranscript.startsWith(interimTranscriptNotCleared.current)) {
                        interimTranscript = interimTranscript.substring(interimTranscriptNotCleared.current.length);
                        if (debug) console.log(timeWithMilliseconds(), 'Interim Transcript after ignoring: ', interimTranscript);
                    }
                }
            }
            if ( prevInterimTranscriptCopyRef.current.toLowerCase().includes(t('talkInterface.stopPlay')) &&
                interimTranscript.toLowerCase().includes(t('talkInterface.stopPlay'))) {
                    // Need to clean this since we already stopped the playback
                    interimTranscript = interimTranscript.replace(t('talkInterface.stopPlay'), '');
            }
            if ( prevInterimTranscriptCopyRef.current.toLowerCase().includes(t('talkInterface.stopPlay')) &&
                finalTranscript.toLowerCase().includes(t('talkInterface.stopPlay'))) {
                    // Need to clean this since we already stopped the playback
                    finalTranscript = finalTranscript.replace(t('talkInterface.stopPlay'), '');
                    // In case this is final transcript, we need to clear the ignore transcript since there is no way it can penetrate the next final transcript
                    ignoreTranscriptDuringPlayback.current = '';
            }
            prevInterimTranscriptCopyRef.current = interimTranscript;


            noSpeechRestartCount.current = 0;
            // Control word detection
            let currentTextPrefix = '';
            let currentText = '';
            //console.log('prevFinalTranscriptCopyRef.current.length', prevFinalTranscriptCopyRef.current.length, prevFinalTranscriptCopyRef.current);

            if ( ignoreInterimTranscriptAlreadySent.current !== '' ) {
                if (debug) console.log(timeWithMilliseconds(), 'Ignoring interim transcript ignoreInterimTranscriptAlreadySent: ', ignoreInterimTranscriptAlreadySent.current);   
                if ( interimTranscript.includes(ignoreInterimTranscriptAlreadySent.current)) {
                    interimTranscript = interimTranscript.replace(ignoreInterimTranscriptAlreadySent.current, '');
                    if (debug) console.log(timeWithMilliseconds(), 'Interim Transcript after ignoring: ', interimTranscript);
                }
                if ( finalTranscript !== '' ) {
                    if ( finalTranscript.includes(ignoreInterimTranscriptAlreadySent.current.trim())) {
                        finalTranscript = finalTranscript.replace(ignoreInterimTranscriptAlreadySent.current.trim(), '');
                        if (debug) console.log(timeWithMilliseconds(), 'Final Transcript includes ignoreInterimTranscriptAlreadySent. Clearing it: ' , finalTranscript);
                    }
                    ignoreInterimTranscriptAlreadySent.current = '';
                }
            }
            
            currentTextPrefix = (startingText.current.length > 0) ? startingText.current.trim() : '';
            currentTextPrefix += (prevFinalTranscriptCopyRef.current.length > 0) ? ' ' + prevFinalTranscriptCopyRef.current.trim()  : '';
            currentTextPrefix = currentTextPrefix.trim();
            currentText = (finalTranscript.length > 0 ) ? finalTranscript : interimTranscript;
            currentText = currentTextPrefix + ' ' + currentText.trim();
            currentText = currentText.trim();
            if (debug) console.log(timeWithMilliseconds(), 'currentText: ', currentText);
            if (interimTranscript.toLowerCase().includes(t('talkInterface.stopPlay')) || finalTranscript.toLowerCase().includes(t('talkInterface.stopPlay'))) {
                if ( isPlaying.current ) {
                    if (audioSourceRef.current) {
                        prevFinalTranscriptCopyRef.current = clearLastMiddleWord(t('talkInterface.stopPlay'),prevFinalTranscriptCopyRef.current);
                        if (debug) console.log(timeWithMilliseconds(), 'Call stop.');
                        audioSourceRef.current.stop();
                        isPlaying.current = false;
                        playbackText.current = '';
                        if (swEchoCancellation.current) {
                            clearTranscript();
                        } else {
                            interimTranscriptNotCleared.current = backupInterimTranscript;
                        }
                        stopAudio();
                        // In case this is final transcript, we need to clear the ignore transcript since there is no way it can penetrate the next final transcript
                        if (finalTranscript.toLowerCase().includes(t('talkInterface.stopPlay'))) {
                            ignoreTranscriptDuringPlayback.current = '';
                        }
                    } else {
                        if (debug) console.log(timeWithMilliseconds(), 'No audio source REF... Really weird. Ignoring control word.' , t('talkInterface.stopPlay'));
                    }
                } else {
                    if (finalTranscript.toLowerCase().includes(t('talkInterface.stopPlay'))) {
                        ignoreTranscriptDuringPlayback.current = '';
                    }
                    if (debug) console.log(timeWithMilliseconds(), 'Playback is not in progress. Ignoring control word.' , t('talkInterface.stopPlay')); 
                }   
                return;
            }
            if (interimTranscript.toLowerCase().includes(t('talkInterface.stopListen')) || finalTranscript.toLowerCase().includes(t('talkInterface.stopListen'))) {
                var newFinal =  clearLastMiddleWord(t('talkInterface.stopListen'),currentText);
                voiceFinalTranscriptUpdate(newFinal);
                stopListening("Control Word Stop")();
                return;
            }
            if (interimTranscript.toLowerCase().includes(t('talkInterface.sendText')) || finalTranscript.toLowerCase().includes(t('talkInterface.sendText'))) {
                const newFinal = clearLastMiddleWord(t('talkInterface.sendText'),currentText);
                if (debug) console.log(timeWithMilliseconds(), 'Send Transcript: ##', newFinal, '##', currentText, '##');
                voiceFinalTranscriptUpdate(newFinal);
                clearLocalTexts();
                voiceRecordingFinish();
                clearTranscript();
                if (finalTranscript === '') {
                    // We are sending the transcript. Based on the interim only, need to make sure if the next interim will include this one need to ignore it.
                    ignoreInterimTranscriptAlreadySent.current = interimTranscript;
                }
                return;
            } 
            
            if (inFlightRequest.current) {
                if (debug) console.log(timeWithMilliseconds(), 'In Flight Request. Ignoring transcript.', interimTranscript);
                ignoreTranscriptDuringPlayback.current = interimTranscript;
                return;
            }
            if (isPlaying.current) {
                if (debug) console.log(timeWithMilliseconds(), 'Playback is in progress. Ignoring transcript.', interimTranscript);
                ignoreTranscriptDuringPlayback.current = interimTranscript;
                return;
            }
            if (ignoreTranscriptDuringPlayback.current !== '') {
                if ( interimTranscript.length > ignoreTranscriptDuringPlayback.current.length && interimTranscript.includes(ignoreTranscriptDuringPlayback.current)) {
                    ignoreTranscriptDuringPlayback.current = interimTranscript;
                }
                if (debug) console.log(timeWithMilliseconds(), 'Ignoring transcript: ', ignoreTranscriptDuringPlayback.current);
                interimTranscript = interimTranscript.replace(ignoreTranscriptDuringPlayback.current, '');

                // Clear the ignore transcript after using it in final transcript
                if (finalTranscript !== '') {
                    finalTranscript = finalTranscript.replace(ignoreTranscriptDuringPlayback.current, '');
                    ignoreTranscriptDuringPlayback.current = '';
                } 
                
                // extract the last word from playbackText and compare it to Interim Transcript
                const lastWord = playbackText.current.split(' ').pop();
                if (lastWord.toLowerCase().includes(interimTranscript)) {
                    if (debug) console.log(timeWithMilliseconds(), 'Interim Transcript includes last word of playbackText. Ignoring transcript.');
                    return;
                }
            }


            // Filter out playback text from final transcript
            if (debug) console.log('playbackText.current, ', playbackText.current , 'playbackText.current.length, ', playbackText.current.length, 'finalTranscript, ', finalTranscript, 'finalTranscript.length, ', finalTranscript.length,
                'playbackText.current.includes(finalTranscript), ', playbackText.current.includes(finalTranscript)
            );
            voiceFinalTranscriptUpdate(currentText);
            if (finalTranscript && !(playbackText.current.length > 0 && finalTranscript.includes(playbackText.current))) {
                if (prevFinalTranscriptCopyRef.current !== '') {
                    finalTranscript = prevFinalTranscriptCopyRef.current.trim() + ' ' + finalTranscript.trim();
                }
                prevFinalTranscriptCopyRef.current = finalTranscript;
                if (debug) console.log(timeWithMilliseconds(), 'Pushing Transcript: ', currentText);
                if (stoppedPressedRef.current) {
                    recognition.stop();
                    if (debug) console.log(timeWithMilliseconds(), '####### Voice recognition paused. Stopping... stoppedPressed ', currentText);
                    return;
                }
            }

            if (interimTranscript || finalTranscript) {
                if (debug) console.log(timeWithMilliseconds(), 'Setting Timeout: currentText: ', currentText);
                const timerId = setTimeout(() => {
                    if (debug) console.log(timeWithMilliseconds(), 'Voice recognition paused. Stopping... setTimeout', currentText);
                    voiceRecordingFinish();
                    clearLocalTexts();
                    if (swEchoCancellation.current) {
                        // This will clear all transcripts
                        clearTranscript();
                    } else {
                        if (finalTranscript === '') {
                            // need to make sure if the next interim will include this one need to ignore it.
                            ignoreInterimTranscriptAlreadySent.current = interimTranscript;
                        }
                    }
                }, PAUSE_DELAY);
                if (Array.isArray(pauseTimeoutRef.current)) {
                    pauseTimeoutRef.current.push(timerId);
                } else {
                    pauseTimeoutRef.current = [timerId];
                }
            }
        };

        recognition.onerror = (event) => {
            if (event.error === 'no-speech') {
                if (debug)  console.log(timeWithMilliseconds(), 'No speech detected. Restarting recognition...');
                noSpeechRestartCount.current += 1;
                if (noSpeechRestartCount.current < 5) {
                    clearTranscript();
                } else {
                    noSpeechRestartCount.current = 0;
                    console.log('No speech detected. Allow Stop');
                }
            } else {
                console.error('Error occurred in recognition:', event.error, event.message, event);
            }
        };

        recognitionRef.current = recognition;
        isInitialized.current = true;
    };

    useEffect(() => {
        if (!('webkitSpeechRecognition' in window)) {
            alert('Your browser does not support Speech Recognition. Try Google Chrome.');
            return;
        }
        // Initialize recognition only if the language has changed
        if (getLanguage() !== languageRef.current) {
            if (languageRef.current !== '') {
                languageRef.current = getLanguage();
                if (debug) console.log(timeWithMilliseconds(), 'Recognition: Language changed. Initializing recognition...');
                initRecognition();
            } else {
                languageRef.current = getLanguage();
            }
        }
    }, [getLanguage]);

    const startListening = async () => {
        if (!isInitialized.current) {
            initRecognition();
            talkStatusUpdate("start-listening");
        }
        
        if (debug) console.log(timeWithMilliseconds(), '----------- 1. Voice recognition started');
        if ( debug ) console.log(timeWithMilliseconds(), 'Recognition: startListening', listening, recognitionRef.current, isStopping, isInitialized.current); 
        if (!listening.current && recognitionRef.current && !isStopping && isInitialized.current) {
            if (debug) console.log(timeWithMilliseconds(), 'Recognition: startListening', getCurrentText());
            startingText.current = getCurrentText();
            listening.current = true;
            setListeningState(true);
            stoppedPressedRef.current = false;
            clearTimers();
            try {
                recognitionRef.current.start();
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        echoCancellation: true,
                        noiseSuppression: true,
                        autoGainControl: true
                    }
                });
                mediaStreamRef.current = stream;
            } catch (error) {
                console.error('Error starting recognition in startListening:', error);
            }
        }
    };

    const stopListening = (reason) => () => {
        if (debug) console.log(timeWithMilliseconds(), '----------- 2. Voice recognition stopListening. Stopping...', reason);
        if (listening && recognitionRef.current && !isStopping) {
            listening.current = false;
            setListeningState(false);
            stoppedPressedRef.current = true;
            clearTimers();
            setIsStopping(true);
            recognitionRef.current.stop();

            if (reason !== "sendTranscript") {
                voiceListeningStopped();
            }

            stopTimeoutRef.current = setTimeout(() => {
                if (debug) console.log(timeWithMilliseconds(), 'Force stopping recognition');
                forceStopRecognition();
            }, 1000);
            prevFinalTranscriptCopyRef.current = '';
        }
    };

    useEffect(() => {
        return () => {
            if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
                if (debug) console.log(timeWithMilliseconds(), 'Closing Audio Context !!!!!!!!!!!!!!!!!!!!!!!!!');
                audioContextRef.current.close();
            }
            if (mediaStreamRef.current) {
                if (debug) console.log(timeWithMilliseconds(), 'Stopping Media Stream !!!!!!!!!!!!!!!!!!!!!!!!!');
                mediaStreamRef.current.getTracks().forEach(track => track.stop());
            }
            if (audioSourceRef.current) {
                if (debug) console.log(timeWithMilliseconds(), 'Stopping Audio Source !!!!!!!!!!!!!!!!!!!!!!!!!');
                audioSourceRef.current.stop();
            }
        };
    }, []);

    return {
        listening: listening.current,
        startListening: startListening,
        stopListening: stopListening,
        clearTranscript: clearTranscript,
    };
};
