import React from 'react';
import { useTranslation } from 'react-i18next';

const UsageSummaryTable = ({ userSummary }) => {
  // Replace \n with <br /> and make sure to handle proper escaping
  const formattedUserSummary = userSummary.replace(/\n/g, '<br />');
  const { t } = useTranslation();

  return (
    <table className="usage-summary-table">
      <tbody>
        <tr>
          <td>{t('userLearnedInformation.userSummary')}</td>
          {/* Use dangerouslySetInnerHTML to render the HTML */}
          <td dangerouslySetInnerHTML={{ __html: formattedUserSummary }} />
        </tr>
      </tbody>
    </table>
  );
};

export default UsageSummaryTable;