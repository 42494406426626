import React, { createContext, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageContext = createContext();

export const useLanguage = () => {
  return useContext(LanguageContext);
};

export const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation();

  const getInitialLanguage = () => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      return storedLanguage;
    }

    const userLanguages = navigator.languages || [navigator.language];
    const supportedLanguages = ['en-US', 'es-ES', 'he-IL'];
    return userLanguages.find((lang) => supportedLanguages.includes(lang)) || 'en-US';
  };

  const [language, setLanguage] = useState(getInitialLanguage());

  const changeLanguage = (lang) => {
    localStorage.setItem('language', lang);
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  useEffect(() => {
    const direction = language === 'he-IL' ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('dir', direction);
    i18n.changeLanguage(language); // Ensure the i18n language is synced
  }, [language, i18n]);

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;