import React from 'react';
import ReactMarkdown from 'react-markdown';
import { FaTrashAlt, FaEdit } from 'react-icons/fa'; // Import the trash and edit icons from react-icons
import '../styles/ChatMessage.css';

function ChatMessage({ message, onDelete, onEdit, isLatestUserMessage, index }) {
  // Only parse markdown for bot messages
  const content = message.text;

  return (
    <div className={`chat-message-container ${isLatestUserMessage && !message.isBot ? 'last-message' : ''}`}>
      <div className={`chat-message ${message.isBot ? 'bot-message' : 'user-message'}`}>
        {content}
        {!message.isBot && isLatestUserMessage && (
          <div className="chat-message-button-container">
            <button className="delete-button" onClick={() => onDelete(index)}>
              <FaTrashAlt />
            </button>
            <button className="edit-button" onClick={() => onEdit(index)}>
              <FaEdit />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatMessage;