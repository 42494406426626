import React, { useEffect, useState } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Select, MenuItem } from '@mui/material';
import '../styles/UserLearnedInformation.css';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UsageSummaryTable from './UsageSummaryTable';

const UserLearnedInformation = React.memo(({ user_learn_info , sessionNumber}) => {
    const [data, setData] = useState({});
    const [usage_summary, setUsageSummary] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [resizeKey, setResizeKey] = useState(Date.now()); // Unique key for forcing reflow
    const [changedFields, setChangedFields] = useState({}); // Track changed fields
    const { t, i18n } = useTranslation();
    const [familyMembers, setFamilyMembers] = useState({});
    const [occupationMembers, setOccupationMembers] = useState({});
    const [userSummary, setUserSummary] = useState('');
    const [newDataLoaded, setNewDataLoaded] = useState(false);

    const API_BASE_URL = window.location.origin === "http://localhost:3000"  ? "http://localhost:8000"  : process.env.REACT_APP_API_BASE_URL;

    const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

    // Define family member object that include name, gender, age, role
    // const familyMember = {
    //     name: '',
    //     role: '',
    //     gender: '',
    //     age: '',
    // };

    // const occupationMember = {
    //     name: '',
    //     occupation: '',
    // };

    const delete_learn_info = () => {
        fetch(API_BASE_URL + '/delete_learn_information', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ sessionNumber })  // Pass sessionNumber in the body
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setData({});
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
        setRefresh(prev => !prev);
    };

    const delete_user_summary = () => {
        fetch(API_BASE_URL + '/delete_user_summary', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ sessionNumber })  // Pass sessionNumber in the body
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            });
        setRefresh(prev => !prev);
    };

    const delete_usage_summary = () => {
        fetch(API_BASE_URL + '/delete_usage_summary', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ sessionNumber })  // Pass sessionNumber in the body
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            });
        setRefresh(prev => !prev);
    };

    const save_learn_info = () => {
        const learn_info = {};
        const learn_vec = [];
        const familyMembersSave = [];
        const occupationMembersSave = [];

        rowsLearnInfo.forEach(row => {
            if ( row.question in familyMembers ) {
                familyMembersSave.push(row.answer);
            } else if (row.question in occupationMembers) {
                occupationMembersSave.push(row.answer);
            } else {            
                const tmp_element = {};
                tmp_element[String(row.question)] = String(row.answer);
                learn_vec.push(tmp_element);
            }
        });

        if (familyMembersSave.length > 0) {
            learn_vec.push({ "family_members": familyMembersSave });
        }
        if (occupationMembersSave.length > 0) {
            learn_vec.push({ "parents_occupation": occupationMembersSave });
        }

        learn_info['UserLearnedInformation'] = learn_vec;
        learn_info['sessionNumber'] = sessionNumber;

        fetch(API_BASE_URL + '/save_learn_information', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(learn_info),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok'); 
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                console.error('Error saving learn information:', error);
            });
    };

    useEffect(() => {
        fetch(API_BASE_URL + '/get_learn_information', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ sessionNumber }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setData(data);
                setNewDataLoaded(true);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });

        fetch(API_BASE_URL + '/get_usage_summary', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ sessionNumber })  // Pass sessionNumber in the body
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setUsageSummary(data);
            })
            .catch(error => {
                setError(error);
            });
        fetch(API_BASE_URL + '/get_user_summary', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            params: { sessionNumber }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            }
            )
            .then(data => {
                console.log(data);
                if (data.length > 0) {
                    setUserSummary(data[0]);
                } else {
                    setUserSummary('');
                }
            }
            )
            .catch(error => {
                setError(error);
            }
            );

    }, [API_BASE_URL, refresh]);

    const formalData = (data) => {
        let data_vec = [];
        let familyMembers = {};
        let occupationMembers = {};
        if ('UserLearnedInformation' in data) {
            if (data['UserLearnedInformation'] !== null && data['UserLearnedInformation'].length > 0)
                data_vec = data['UserLearnedInformation'];
        }
        let formal_data = {};
        for (const data_item of data_vec) {
            for (const key in data_item) {
                if (data_item.hasOwnProperty(key)) {
                    const element = data_item[key];
                    if (key === "family_members") {
                        let i = 1;
                        for (const member of element) {
                            let member_str = i + ". " + t('userLearnedInformation.role.'+member['role']);
                            formal_data[member_str] = member;
                            familyMembers[member_str] = member;
                            //console.log(member);
                            i++;
                        }
                    } else if (key === "parents_occupation") {
                        let i = 1;
                        if (element !== null && Array.isArray(element))
                            for (const member of element) {
                                let member_str = i + ". Occupation: " + member.name;
                                //let member_val = element[member]['occupation'];
                                formal_data[member_str] = member;
                                occupationMembers[member_str] = member;
                                console.log(member);
                                i++;
                            }
                        else
                            formal_data["parents_occupation"] = element;
                    } else {
                        formal_data[key] = element;
                    }
                }
            }
        }
        setFamilyMembers(familyMembers);
        setOccupationMembers(occupationMembers);
        return formal_data;
    };

    const [rowsLearnInfo, setRowsLearnInfo] = useState(() => {
        console.log("setRowsLearnInfo: ",  data);
        const formattedData = formalData(data);
        return Object.entries(formattedData).map(([key, value]) => ({ question: key, answer: value }));
    });

    useEffect(() => {
        //console.log("formattedData change: ",  data);
        if (newDataLoaded) {
            const formattedData = formalData(data);
            setRowsLearnInfo(Object.entries(formattedData).map(([key, value]) => ({ question: key, answer: value })));
            setNewDataLoaded(false);
        }
    }, [data, formalData]);

    const handleInputChange = (index, event, key = '') => {
        const newRows = [...rowsLearnInfo];
        if (key) {
            newRows[index].answer[key] = event.target.value;
        } else
            newRows[index].answer = event.target.value;
        setRowsLearnInfo(newRows);

        // Mark this field as changed
        setChangedFields(prevState => ({
            ...prevState,
            [index]: true,
        }));
    };

    // Handle window resize event
    useEffect(() => {
        const handleResize = () => {
            setResizeKey(Date.now());
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (loading) return <p className="loading">Loading...</p>;
    if (error) return <p className="error">Error loading data: {error.message}</p>;

    return (
        <div className="table-container">
            <TableContainer component={Paper} key={resizeKey}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('userLearnedInformation.question')}</TableCell>
                            <TableCell>{t('userLearnedInformation.answer')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowsLearnInfo.map((row, index) => (
                            !row.question.includes('rate') ? (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {(i18n.exists('userLearnedInformation.question_mapping.' + row.question)) ?
                                     t('userLearnedInformation.question_mapping.' + row.question) : row.question}
                                </TableCell>
                                <TableCell>
                                { row.question in familyMembers  ? (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {(row.answer.name !== undefined) && (
                                            <Typography variant="body2" style={{ marginRight: 10 }}>
                                               {t('userLearnedInformation.name')}:
                                                    <TextField
                                                    value={row.answer.name || ''}
                                                    onChange={(event) => handleInputChange(index, event, 'name')}
                                                    variant="outlined"
                                                    className={changedFields[index] ? 'changed-field' : ''}
                                                />
                                            </Typography>
                                    )}
                                        {(row.answer.age !== undefined) && (
                                            <Typography variant="body2" style={{ marginRight: 10 }}>
                                                {t('userLearnedInformation.age')}:
                                                <TextField
                                                    value={row.answer.age}
                                                    onChange={(event) => handleInputChange(index, event, 'age')}
                                                    variant="outlined"
                                                    className={changedFields[index] ? 'changed-field' : ''}
                                                />
                                            </Typography>
                                        )}
                                        {row.answer.gender && (
                                             <Typography variant="body2" style={{ marginRight: 5 }}>
                                             {t('userLearnedInformation.gender')}:
                                            <Select
                                                value={row.answer.gender}
                                                onChange={(event) => handleInputChange(index, event, 'gender')}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                style={{ minWidth: 95 }}
                                                className={changedFields[index] ? 'changed-field' : ''}
                                            >
                                                <MenuItem value={'male'}>{t('userLearnedInformation.male')}</MenuItem>
                                                <MenuItem value={'female'}>{t('userLearnedInformation.female')}</MenuItem>
                                                <MenuItem value={'other'}>{t('userLearnedInformation.other')}</MenuItem>
                                            </Select>
                                            </Typography>
                                        )}
                                     </div>
                                 ) : row.question in occupationMembers ? (
                                    <TextField
                                        multiline
                                        value={row.answer.occupation} 
                                        onChange={(event) => handleInputChange(index, event, 'occupation')} 
                                        variant="outlined"
                                        fullWidth
                                        className={changedFields[index] ? 'changed-field' : ''}
                                    />
                                 ) : (
                                    <TextField
                                        multiline
                                        value={row.answer}
                                        onChange={(event) => handleInputChange(index, event)}
                                        variant="outlined"
                                        fullWidth
                                        className={changedFields[index] ? 'changed-field' : ''}
                                    />
                                 )}
                                </TableCell>
                            </TableRow>
                        ): null))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br /><br /><br />
            <div className="button-container">
                <button className="ok-button" onClick={() => user_learn_info()}>{t('userLearnedInformation.ok')}</button>
                <button className="clear-button" onClick={() => delete_learn_info()}>{t('userLearnedInformation.clearLearn')}</button>
                <button className="save-changes" onClick={() => save_learn_info()}>{t('userLearnedInformation.saveChanges')}</button>
            </div>
            <br /><br /><br />
            <table className="usage-summary-table">
        <tbody>
        {(parseFloat(usage_summary.num_calls_gpt4o)>0) && (
        <>
          <tr>
            <td>{t('userLearnedInformation.completionTokensGpt4o')}</td>
            <td>{parseFloat(usage_summary.completion_tokens_gpt4o).toLocaleString()}</td>
          </tr>
          <tr>
            <td>{t('userLearnedInformation.promptTokensGpt4o')}</td>
            <td>{parseFloat(usage_summary.prompt_tokens_gpt4o).toLocaleString()}</td>
          </tr>
          <tr>
            <td>{t('userLearnedInformation.numCallsGpt4o')}</td>
            <td>{parseFloat(usage_summary.num_calls_gpt4o).toLocaleString()}</td>
          </tr>
          </>
        )}
        {(parseFloat(usage_summary.num_calls_gpt4oMini)>0) && (
        <>
          <tr>
            <td>{t('userLearnedInformation.completionTokensGpt4oMini')}</td>
            <td>{parseFloat(usage_summary.completion_tokens_gpt4oMini).toLocaleString()}</td>
          </tr>
          <tr>
            <td>{t('userLearnedInformation.promptTokensGpt4oMini')}</td>
            <td>{parseFloat(usage_summary.prompt_tokens_gpt4oMini).toLocaleString()}</td>
          </tr>
          <tr>
            <td>{t('userLearnedInformation.numCallsGpt4oMini')}</td>
            <td>{parseFloat(usage_summary.num_calls_gpt4oMini).toLocaleString()}</td>
          </tr>
          </>
        )}

          {(parseFloat(usage_summary.num_calls_gpt3)>0) && (
            <>
                <tr>
                    <td>{t('userLearnedInformation.completionTokensGpt3')}</td>
                    <td>{parseFloat(usage_summary.completion_tokens_gpt3).toLocaleString()}</td>
                </tr>
                <tr>
                    <td>{t('userLearnedInformation.promptTokensGpt3')}</td>
                    <td>{parseFloat(usage_summary.prompt_tokens_gpt3).toLocaleString()}</td>
                </tr>
                <tr>
                    <td>{t('userLearnedInformation.numCallsGpt3')}</td>
                    <td>{parseFloat(usage_summary.num_calls_gpt3).toLocaleString()}</td>
                </tr>
            </>
            )}
            {(parseFloat(usage_summary.num_calls_sonnet)>0) && (
            <>
                <tr>
                    <td>{t('userLearnedInformation.completionTokensSonnet')}</td>
                    <td>{parseFloat(usage_summary.completion_tokens_sonnet).toLocaleString()}</td>
                </tr>
                <tr>
                    <td>{t('userLearnedInformation.promptTokensSonnet')}</td>
                    <td>{parseFloat(usage_summary.prompt_tokens_sonnet).toLocaleString()}</td>
                </tr>
                <tr>
                    <td>{t('userLearnedInformation.numCallsSonnet')}</td>
                    <td>{parseFloat(usage_summary.num_calls_sonnet).toLocaleString()}</td>
                </tr>
            </>
            )}

          <tr>
            <td>{t('userLearnedInformation.audioCharacters')}</td>
            <td>{parseFloat(usage_summary.audio_characters).toLocaleString()}</td>
          </tr>
          <tr>
            <td>{t('userLearnedInformation.totalDurationMicroseconds')}</td>
            <td>{parseFloat(usage_summary.time_took).toLocaleString()}</td>
          </tr>
          <tr>
            <td>{t('userLearnedInformation.cost')}</td>
            <td>{parseFloat(usage_summary.cost).toFixed(3)} $</td>
          </tr>
          <tr>
            <td>{t('userLearnedInformation.actual_cost')}</td>
            <td>{parseFloat(usage_summary.actual_cost).toFixed(3)} $</td>
          </tr>

        </tbody>
      </table>
      <br /><br /><br />
        <UsageSummaryTable userSummary={userSummary} />
      <div className="button-container">
        <button className="ok-button" onClick={() => user_learn_info()}>{t('userLearnedInformation.ok')}</button>
        {(ENVIRONMENT === 'development') && (
        <button className="clear-usage-button" onClick={() => delete_usage_summary()}>{t('userLearnedInformation.clearUsage')}</button>
        )}
        <button className="clear-history-summary" onClick={() => delete_user_summary()}>{t('userLearnedInformation.clearSummary')}</button>
      </div>
    </div>
  );
});

export default UserLearnedInformation;