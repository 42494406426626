import React, { useState, useEffect } from 'react';
import ChatInterface from './components/ChatInterface';
import WelcomingPage from './components/WelcomingPage';

import axios from 'axios';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useCookies } from 'react-cookie';
import { LanguageProvider } from './components/LanguageContext';
import SessionSidebar from './components/SessionSidebar';

// import LogRocket from 'logrocket';
// LogRocket.init('yozrvu/adler');

// LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
//   name: 'Ariel Noy',
//   email: 'ariel@noyai.dev',

//   // Add your own custom user variables here, ie:
//   subscriptionType: 'pro'
// });

function App() {
  // Mobile local development app debugging
  //const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [lastInteraction, setLastInteraction] = useState(null);
  const [sessionNumber, setSessionNumber] = useState(0);
  const [reloadSidebar, setReloadSidebar] = useState(0);

  const API_BASE_URL = window.location.origin === "http://localhost:3000"  ? "http://localhost:8000"  : process.env.REACT_APP_API_BASE_URL;

  const api = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
  });
  const google_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const [cookies, setCookie, removeCookie] = useCookies([]);

  const loadLastInteraction = async () => {
    try {
      const response = await api.get('/get_last_interaction', { 
        withCredentials: true,
        params: { sessionNumber }
      });
      setLastInteraction(response.data.interaction);
    } catch (error) {
      console.error('Error loading last interaction', error);
    }
  }

  useEffect(() => {
    // Check authentication status on page load
    const checkAuthStatus = async () => {
      try {
      const response = await api.get(API_BASE_URL+'/auth/status', { withCredentials: true });
        setIsAuthenticated(response.data.authenticated);
        if (response.data.authenticated) {
          console.log('Authenticated');
          loadLastInteraction();
        } else
          console.log('Not authenticated');
      } catch (error) {
        console.error('Error checking authentication status', error);
      } finally {
        setIsLoading(false); // Set loading to false after the check
      }
    };

    checkAuthStatus();
  }, []);

  useEffect(() => {
    console.log('Session number changed in APP:', sessionNumber);
    loadLastInteraction();
  }, [sessionNumber]);

  const handleLoginSuccess = (user) => {
    setIsAuthenticated(true);
    console.log('Logged in user:', user);
    //# refresh the page to show the chat interface
    window.location.reload();
  };

  const triggerReloadSideBar = () => {
    setReloadSidebar(reloadSidebar + 1);
  }

  const logout =  (request) => {
    setIsAuthenticated(false);
    console.log('Logging out' , cookies);
    const mylogout = async () => {
      try {
        
        const response = await api.post('/auth/logout', { withCredentials: true });
        console.log('Logout response:', response);
      } catch (error) {
        console.error('Error during logout', error);
      }
      console.log('Logging out' , cookies);
      removeCookie('adler', { path: '/' });
      };
    mylogout();
  };
  // Conditional rendering based on loading state
  if (isLoading) {
    return <div>Loading...</div>; // Show a loading indicator while checking authentication status
  }

  
  return (
    <GoogleOAuthProvider clientId={google_client_id}>
      <LanguageProvider>
        <div className="App">
          {!isAuthenticated ? (
            <div style={{  width: '100%', height: '100%' }}>
              <WelcomingPage handleLoginSuccess={handleLoginSuccess} />
            </div>
          ) : (
            <div>
            <SessionSidebar setSessionNumber={setSessionNumber} reloadSidebar={reloadSidebar}/>
            <ChatInterface lastInteraction={lastInteraction} logout={(logout)} sessionNumber={sessionNumber} triggerReloadSideBar={triggerReloadSideBar}/>
            </div>
          )}
        </div>
      </LanguageProvider>
    </GoogleOAuthProvider>
  );
}

export default App;