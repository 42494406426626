// src/components/WelcomingPage.js
import React, { useContext, useState } from 'react';
import '../styles/WelcomingPage.css';
import GoogleLoginButton from './GoogleLoginButton';
import { useTranslation, Trans } from 'react-i18next';
import LanguageContext from './LanguageContext';

const WelcomingPage = ({ handleLoginSuccess }) => {
  const { t } = useTranslation();
  const { language, changeLanguage } = useContext(LanguageContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
    setDropdownOpen(false); // Close the dropdown after selecting a language
  };

  return (
    <div>
      <header>
        <nav>
          <div className="logo">Parenting<span className="highlight">AI</span></div>
          <ul>
            <li><a href="#home">{t('WelcomingPage.home')}</a></li>
            <li><a href="#about-us">{t('WelcomingPage.aboutUs')}</a></li>
          </ul>
          <div className="language-switcher">
            <button onClick={toggleDropdown}>
              {language.toUpperCase().substring(0, 2)} <span>&#9660;</span>
            </button>
            {dropdownOpen && (
              <div className="dropdown-menu">
                <button onClick={() => handleLanguageChange('en-US')}>EN</button>
                <button onClick={() => handleLanguageChange('es-ES')}>ES</button>
                <button onClick={() => handleLanguageChange('fr-FR')}>FR</button>
                <button onClick={() => handleLanguageChange('de-DE')}>DE</button>
                <button onClick={() => handleLanguageChange('he-IL')}>HE</button>
              </div>
            )}
          </div>
        </nav>
      </header>

      <main>
        <section className="hero">
          <h1>{t('WelcomingPage.welcomeMessage')}</h1>
          {/* <div className="cta-buttons">
            <button className="btn primary-btn">{t('WelcomingPage.getStarted')}</button>
          </div> */}
        </section>
        <section className="login-section"> 
          <img className="logo" src={process.env.PUBLIC_URL + '/logo512o.png'} alt="Logo" />
          <div className="playing">
            <img src={process.env.PUBLIC_URL + '/playing.png'} alt="Playing" />
            <h1>{t('WelcomingPage.loginWithGoogle')}</h1>
            <GoogleLoginButton onSuccess={handleLoginSuccess} />
          </div>
        </section>
        <section className="about-us" id="about-us">
          <h2>{t('WelcomingPage.aboutUsSection.title')}</h2>
          <h3>{t('WelcomingPage.aboutUsSection.understandingBehavior')}</h3>
          <p><Trans i18nKey="WelcomingPage.aboutUsSection.intro" /></p>
          <ul><ul>
            <li><Trans i18nKey="WelcomingPage.aboutUsSection.examples.e1" /></li>
            <li><Trans i18nKey="WelcomingPage.aboutUsSection.examples.e2" /></li>
            <li><Trans i18nKey="WelcomingPage.aboutUsSection.examples.e3" /></li>
            <li><Trans i18nKey="WelcomingPage.aboutUsSection.examples.e4" /></li>
            </ul>
          </ul>
          <br />
          <p><Trans i18nKey="WelcomingPage.aboutUsSection.reality" /></p>
          <p><Trans i18nKey="WelcomingPage.aboutUsSection.feelings" /></p>
          <br />
          <p><Trans i18nKey="WelcomingPage.aboutUsSection.challenge" /></p>
          <ul>
            <p><Trans i18nKey="WelcomingPage.aboutUsSection.questions1" /></p>
            <p><Trans i18nKey="WelcomingPage.aboutUsSection.questions2" /></p>
          </ul>
          <h3>{t('WelcomingPage.aboutUsSection.needForBelonging')}</h3>
          <p>{t('WelcomingPage.aboutUsSection.belongingIntro')}</p>
          <h3>{t('WelcomingPage.aboutUsSection.understandingDisruptive')}</h3>
          <p>{t('WelcomingPage.aboutUsSection.disruptiveIntro')}</p>
          <h3>{t('WelcomingPage.aboutUsSection.keyPoints')}</h3>
          <ul>
            <li>{t('WelcomingPage.aboutUsSection.keyPointsVec.p1')}</li>
            <li>{t('WelcomingPage.aboutUsSection.keyPointsVec.p2')}</li>
          </ul>
          <p>{t('WelcomingPage.aboutUsSection.behaviors')}</p>
          <h3>{t('WelcomingPage.aboutUsSection.ourApproach')}</h3>
          <p>{t('WelcomingPage.aboutUsSection.approachIntro')}</p>
        </section>
      </main>

      <footer>
        <p>&copy; 2024 ParentingAI. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default WelcomingPage;