import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/ChatTalkLegend.css'; // Import the CSS file if using external stylesheet

const ChatTalkLegend = () => {
  const { t } = useTranslation();


  return (
    <div className='chat-talk-legend-text'>
        <h2>{t('talkInterface.ListenKeywords')}:</h2>
        <h2>&nbsp;&nbsp;&nbsp;&nbsp;{t('talkInterface.stopPlay')}</h2>
        <h2>&nbsp;&nbsp;&nbsp;&nbsp;{t('talkInterface.sendText')}</h2>
        <h2>&nbsp;&nbsp;&nbsp;&nbsp;{t('talkInterface.stopListen')}</h2>
    </div>
  );
};

export default ChatTalkLegend;