import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faHandPaper } from '@fortawesome/free-solid-svg-icons';
import '../styles/TalkInterface.css';
import { useRecognition } from './Recognition.js';
import timeWithMilliseconds from './timeWithMilliseconds';

function TalkInterface({
    voiceRecordingFinish,
    getLanguage,
    voiceFinalTranscriptUpdate,
    voiceListeningStopped,
    getCurrentText,
    audioUrl,
    audioText,
    voiceAllowedToPlay,
    updateShowTalkLegend,
    clearAudioURL,
    talkStatusUpdate,
    inFlightRequest,
    submitCount
}) {

    const [listening, setListening] = useState(false);
    const { t } = useTranslation();

    const audioContextRef = useRef(null);
    const isPlaying = useRef(false);
    const playbackText = useRef('');
    const audioSourceRef = useRef(null);
    const gainNodeRef = useRef(null);
    const secondPartAudioUrl = useRef('');
    const API_BASE_URL = window.location.origin === "http://localhost:3000"  ? "http://localhost:8000"  : process.env.REACT_APP_API_BASE_URL;
    const swEchoCancellation = useRef(false);
    const lastURL = useRef('');
    const lastAudioURL = useRef('');

    const debug = true;

    const stopAudio = useCallback(() => {
        if (debug) console.log(timeWithMilliseconds(), 'Force Stopping Audio in stopAudio...');
        clearAudioURL();
        secondPartAudioUrl.current = '';
        playbackText.current = '';
        if (audioSourceRef.current) {
            audioSourceRef.current.stop();
        }
        isPlaying.current = false;
    }, [clearAudioURL, debug]);

    
    const setListeningState = useCallback((newListeningState) => {
        setListening(newListeningState);
        updateShowTalkLegend(newListeningState);
        if (debug) console.log(timeWithMilliseconds(), 'TalkInterface setListeningState', newListeningState);
    } , []);

    const stopListingingPressed = useCallback(() => {
        if (debug) console.log(timeWithMilliseconds(), 'Stop Listening Pressed...');
        recognitionRef.current.stopListening("stopListingingPressed")();
        talkStatusUpdate('');
    }, [debug]);

    const recognitionRef = useRef(useRecognition({
        getLanguage,
        voiceRecordingFinish,
        voiceFinalTranscriptUpdate,
        voiceListeningStopped,
        getCurrentText,
        audioSourceRef,
        isPlaying,
        playbackText,
        audioContextRef,
        setListeningState,
        stopAudio,
        swEchoCancellation,
        talkStatusUpdate,
        inFlightRequest,
        submitCount
    }));

    const sendTranscript = useCallback(() => {
        if (debug) console.log(timeWithMilliseconds(), '----------- 3. Sending Transcript...');
        recognitionRef.current.stopListening("sendTranscript")();
        voiceRecordingFinish();
    }, [debug, voiceRecordingFinish]);

    const playAudio = useCallback(async (url, text) => {
        if (audioContextRef.current && audioContextRef.current.state === 'closed') {
            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        }
        if (! audioContextRef.current ) {
            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        }

        if (url.includes('_')) {
            var FirstPartURL = url.substring(0, url.indexOf('_'));
            var SecondPartURL = url.substring(url.indexOf('_') + 1);
            url = FirstPartURL;
            secondPartAudioUrl.current = SecondPartURL;
        }
        url = API_BASE_URL + '/audio_response/' + url;
        if (lastURL.current === url) {
            //if (debug) console.log(timeWithMilliseconds(), 'Same URL, so not playing again', url);
            return;
        }

        isPlaying.current = true;
        playbackText.current = text;

        lastURL.current = url;
        if (debug) console.log(timeWithMilliseconds(), 'Fetching audio response', url, text);
        talkStatusUpdate('getting-audio');
        try {
            const response = await fetch(url);
            console.log(response.headers.get('Content-Type')); // Check the content type
            if (response.status !== 200) {
                if (debug) console.log(timeWithMilliseconds(), 'Error in fetching audio response', url , response.status, response.statusText);
                isPlaying.current = false;
                playbackText.current = '';
                return;
            }
            const arrayBuffer = await response.arrayBuffer();
            console.log(arrayBuffer.byteLength); // Check if it's a reasonable size
            if (arrayBuffer.byteLength === 0) {
                console.error("Received empty ArrayBuffer");
                isPlaying.current = false;
                playbackText.current = '';
                return;
            }

            try {
                const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);

                const source = audioContextRef.current.createBufferSource();
                source.buffer = audioBuffer;
                audioSourceRef.current = source;

                const gainNode = audioContextRef.current.createGain();
                gainNodeRef.current = gainNode;
                source.connect(gainNode);
                gainNode.connect(audioContextRef.current.destination);

                source.start();
                if ( debug ) console.log(timeWithMilliseconds(), 'Playing audio 2', url, text);
                talkStatusUpdate('playing-audio');
                source.onended = () => {
                    if (debug) console.log(timeWithMilliseconds(), 'Playback ended in onended', audioSourceRef.current, isPlaying.current, secondPartAudioUrl.current);
                    if (secondPartAudioUrl.current !== '') {
                        if (debug) console.log(timeWithMilliseconds(), 'Playing second part of the audio', secondPartAudioUrl.current);
                        var remainingAudioURL = secondPartAudioUrl.current;
                        secondPartAudioUrl.current = '';
                        playAudio(remainingAudioURL, text);
                        return;
                    } else {
                        clearAudioURL();
                    }
                    talkStatusUpdate('');
                    if (isPlaying.current) {
                        isPlaying.current = false;
                        playbackText.current = '';
                        audioSourceRef.current = null;
                        gainNodeRef.current = null;
                        if (debug) console.log(timeWithMilliseconds(), 'Playback ended in IsPlaying');
                        if (swEchoCancellation.current) {
                            recognitionRef.current.clearTranscript();
                        }
                    }
                };    
            }   catch (error) {
                console.error("Error decoding audio data:", error);
                isPlaying.current = false;
                playbackText.current = '';
            }
        } catch (error) {
            console.error("Error fetching or handling audio data:", error);
            isPlaying.current = false;
            playbackText.current = '';
        }

    }, [API_BASE_URL, clearAudioURL, debug]);

    const initializeAudioContext = useCallback(() => {
        if (!audioContextRef.current || audioContextRef.current.state === 'closed') {
            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        } else if (audioContextRef.current.state === 'suspended') {
            audioContextRef.current.resume();
        }
    }, []);

  
    useEffect(() => {
        if (isPlaying.current && audioUrl === '') {
            if (audioSourceRef.current) {
                audioSourceRef.current.stop();
            }
            isPlaying.current = false;
            playbackText.current = '';
            if (swEchoCancellation.current) {
                recognitionRef.current.clearTranscript();
            }
        }
        if (audioUrl && audioText) {
            if ( audioUrl === lastAudioURL.current ) {
                //if (debug) console.log(timeWithMilliseconds(), 'Same URL, so not playing again', audioUrl);
                return;
            }
            console.log(timeWithMilliseconds(), 'Call Playing audio 1', audioUrl, audioText);
            lastAudioURL.current = audioUrl;
            playAudio(audioUrl, audioText);
        }
    }, [audioUrl, audioText, playAudio]);


    useEffect(() => {
        return () => {
            if (!voiceAllowedToPlay && isPlaying.current ) {
                if (debug) console.log(timeWithMilliseconds(), 'Voice stop comming from parent...', voiceAllowedToPlay);
                stopAudio();
            }
        }
    }, [voiceAllowedToPlay, stopAudio, debug]);

    const clickStartTalk = () => {
        initializeAudioContext(); 
        recognitionRef.current.startListening(); 
        setListening(true);
    };
    return (
        <div className="listen-buttons">
            {!listening ? (
                <button onClick={() => { clickStartTalk() }} className="press-to-talk-button">
                    {t('talkInterface.pressToTalk')}
                </button>
            ) : (
                <>
                    <button onClick={stopListingingPressed} className="talk-stop-button">
                        <FontAwesomeIcon icon={faHandPaper} /><p></p>
                        {t('talkInterface.stop')}
                    </button>
                    <button onClick={sendTranscript} className="talk-send-button">
                        <FontAwesomeIcon icon={faPaperPlane} /><p></p>
                        {t('talkInterface.send')}
                    </button>
                </>
            )}
        </div>
    );
}

export default TalkInterface;