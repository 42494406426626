import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ChatMessage from './ChatMessage';
import TalkInterface from './TalkInterface';
import Modal from 'react-modal';
import Header from './Header';
import UserLearnedInformation from './UserLearnedInformation';
import DebugInformation from './DebugInformation';
import LanguageContext from './LanguageContext';
import ChatTalkLegend from './ChatTalkLegend';
import timeWithMilliseconds from './timeWithMilliseconds';
import ChatTalkStatus from './ChatTalkStatus';
import VerticalProgressBar from './VerticalProgressBar';
import { use } from 'i18next';
import { listItemSecondaryActionClasses } from '@mui/material';

function ChatInterface({ lastInteraction, logout, sessionNumber, triggerReloadSideBar }) {
  const { t } = useTranslation();
  const { language, changeLanguage } = useContext(LanguageContext);

  const WelcomeMessage = { text: (sessionNumber == 0 ?  t('chat.welcomeMessage') :  t('chat.newSessionMessage') ), isBot: true };

  const [trigger, setTrigger] = useState(0);

  const messages = useRef([WelcomeMessage]);
  const [inputText, setInputText] = useState('');
  const inputTextRef = useRef('');
  const messagesEndRef = useRef(null);
  const [playAudio, setPlayAudio] = useState(true);
  const [isPreDefSessionModalOpen, setIsPreDefSessionModalOpen] = useState(false);
  const [isLegendModalOpen, setIsLegendModalOpen] = useState(false);
  const [userLearnInfo, setUserLearnInfo] = useState(false);
  const [hover, setHover] = useState(false);
  const [debugInfo, setDebugInfo] = useState(false);
  const [selectedSessionName, setSelectedSessionName] = useState("");
  const [selectedSessionID, setSelectedSessionID] = useState("");
  const [sessionNames, setSessionNames] = useState([]);
  const [my_sessions, setMySessions] = useState([]);
  const [isVoiceListening, setIsVoiceListening] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const [audioText, setAudioText] = useState('');
  const textareaRef = useRef(null);
  const inFlightRequest = useRef(false);
  const [voiceAllowedToPlay, setVoiceAllowedToPlay] = useState(true);

  const [showSideBar, setShowSideBar] = useState(false);
  const showTalkLegend = useRef(false);
  const [showTalkStatus, setShowTalkStatus] = useState(false);
  const [talkStatus, setTalkStatus] = useState('');
  const [showProgressBar, setShowProgrssBar] = useState(false);
  const [lastSessionNumber , setLastSessionNumber] = useState(0);
  const [submitCount, setSubmitCount] = useState(0);

  const debug = true;

  const API_BASE_URL = window.location.origin === "http://localhost:3000"  ? "http://localhost:8000"  : process.env.REACT_APP_API_BASE_URL;
  const google_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [lastBotMessage, setLastBotMessage] = useState({ text: '', isBot: true });
  const [lastBotMessageIndex, setLastBotMessageIndex] = useState(0);
  const progressTable = [
    "family_info",
    "specific_case",
    "background_info",
    "guidance"
  ];
  const steps = [
    { name: t('chat.VerticalProgressBar.Step1'), completed: false},
    { name: t('chat.VerticalProgressBar.Step2') , completed: false},
    { name: t('chat.VerticalProgressBar.Step3') , completed: false},
    { name: t('chat.VerticalProgressBar.Step4') , completed: false},
  ];

  const [ updatedProgress , setUpdatedProgress ] = useState(steps);


  // pass progressOn flag to turn on the progress bar this time -- default is false
  const showSideBarOn = (progressOn = false) => {
    //if (debug) console.log(timeWithMilliseconds(), 'Show sidebar', progressOn);
    setShowProgrssBar(progressOn);
    setShowSideBar(true);
    const timer = setTimeout(() => {
      setShowSideBar(false);
      console.log('Close sidebar');
    }, 3000);
  };

  const updatedProgressFunc = () => {
    let cleanSteps = steps;
    for (let i = 0; i < progressTable.length; i++) {
      if (messages.current.some((message) => message.progress === progressTable[i])) {
        if (updatedProgress[i].completed === false) {
          // Reset the progress trigger to show the progress bar again
          if (debug) console.log(timeWithMilliseconds(), 'Show progress bar', progressTable[i]);
          showSideBarOn(true);
        }
        cleanSteps[i].completed = true;
      }
    }
    setUpdatedProgress(cleanSteps);
  };

  useEffect(() => {
    if (sessionNumber !== lastSessionNumber) {
      setLastSessionNumber(sessionNumber);
      if (debug) console.log(timeWithMilliseconds(), 'Session number changed', sessionNumber);
      messages.current = [WelcomeMessage]; 
      triggerReloadSideBar();
    }
  }, [sessionNumber]);
  
  useEffect(() => {
    if (lastInteraction) {
      messages.current = lastInteraction.chatHistory || [];
      setPlayAudio(lastInteraction.playAudio !== '0');
      setVoiceAllowedToPlay(lastInteraction.playAudio !== '0');
      updatedProgressFunc();
    }
  }, [lastInteraction]);

  useEffect(() => {
    scrollToBottom();
  }, [trigger]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const togglePlayAudio = () => {
    const prevPlayAudio = playAudio;
    setPlayAudio(!playAudio);
    if (debug) console.log(timeWithMilliseconds(), 'Toggle play audio', !prevPlayAudio);
    if (prevPlayAudio) {
      clearAudioURL();
      setVoiceAllowedToPlay(false);
    } else {
      setVoiceAllowedToPlay(true);
    }
  };

  const clearAudioURL = () => {
    setAudioUrl('');
    setAudioText('');
  };

  useEffect(() => {
    scrollToBottom();
    const timer = setTimeout(scrollToBottom, 100);
    return () => clearTimeout(timer); // Clean up the timeout if the component unmounts
  }, [messages.current]);

  useEffect(() => {
    console.log('Set Welcome message' + messages.current.length);
    if (messages.current.length == 1) {
      messages.current = [WelcomeMessage];
    }
  }, [language]);

  useEffect(() => {
    inputTextRef.current = inputText;
  }, [inputText]);

  const voiceRecordingFinish = () => {
    if (debug) console.log(timeWithMilliseconds(), 'Voice recording finished called', inputText);
    setInputText((prevInputText) => {
      if (prevInputText !== '') {
        setIsVoiceListening(false);
        return prevInputText + "VoiceCommand";
      }
      return prevInputText;
    });
  };

  const voiceFinalTranscriptUpdate = async (text) => {
    if (debug) console.log(timeWithMilliseconds(), 'Voice final transcript update called', text);
    setIsVoiceListening(true);
    setInputText(text);
  };

  const voiceListeningStopped = () => {
    setIsVoiceListening(false);
  };

  const getCurrentInputText = () => {
    if (debug) console.log(timeWithMilliseconds(), 'Get current input text called', inputTextRef.current);
    return inputTextRef.current;
  };

  const updateShowTalkLegend = (legend) => {
    if (messages.current.length === 1) {
      console.log('Show legend', messages.current.length);
      setIsLegendModalOpen(legend);
    }
    // If the legend is already open, don't show it again
    if (showTalkLegend.current !== legend) {
      if (debug) console.log(timeWithMilliseconds(), 'Update show talk legend: ', legend, ' showTalkLegend: ', showTalkLegend.current);
      showTalkLegend.current = legend;
      showSideBarOn();
    }
  };

  const talkStatusUpdate = (status) => {
    if (status === '') {
      setTalkStatus(status);
      setShowTalkStatus(false);
    } else {
      if (status !== talkStatus) {
        if (debug) console.log(timeWithMilliseconds(), 'Talk status update called status:', status, ' talkStatus:', talkStatus);
        setTalkStatus(status);
        setShowTalkStatus(true);
        showSideBarOn();
      }
    }
  };

  const talkStatusClear = () => {
    setShowTalkStatus(false);
    setTalkStatus('');
  };

  useEffect(() => {
    if (textareaRef.current) {
      const textareaElement = textareaRef.current;
      textareaElement.focus();
      textareaElement.setSelectionRange(inputText.length, inputText.length);
      textareaElement.scrollTop = textareaElement.scrollHeight;
    }
  }, [inputText]);

  const getLanguage = () => {
    return language;
  };

  const clearMessages = () => {
    triggerReloadSideBar();
  };

  const cleanTalkInterfaceDependencies = () => {
    clearAudioURL();
    setIsVoiceListening(false);
  };

  const user_learn_info = () => {
    if (debug) console.log(timeWithMilliseconds(), 'User learn info called');
    cleanTalkInterfaceDependencies();
    setUserLearnInfo((prev) => !prev);
  };

  const debug_info = () => {
    cleanTalkInterfaceDependencies();
    setDebugInfo((prev) => !prev);
  };

  const resetMessages = () => {
    messages.current = [WelcomeMessage];
    setTrigger(prevTrigger => prevTrigger + 1);
  };

  useEffect(() => {
    if (!selectedSessionName) {
      return;
    }
    const session = my_sessions.find(session => session.session_name === selectedSessionName);
    if (!session) {
      console.error('Selected session not found');
      return;
    }
    setSelectedSessionID(session.session_id);
    messages.current = session.chatHistory || [WelcomeMessage];
    setPlayAudio(session.playAudio || false);
  }, [selectedSessionName]);

  const loadSession = async () => {
    const response = await fetch(API_BASE_URL + '/get_demo_sessions');
    if (!response.ok) {
      console.error('Failed to fetch sessions');
      return;
    }
    const sessions = await response.json() || [];
    if (!sessions || !sessions['sessions']) {
      console.error('No sessions found');
      return;
    }
    console.log('Sessions:', sessions);
    setMySessions(sessions['sessions']);
    setSessionNames(sessions['sessions'].map(session => session.session_name));

    setIsPreDefSessionModalOpen(true);
    if (!selectedSessionName) {
      return;
    }
  };
  const loadPredifendSession = async () => {
    let session = my_sessions.find(session => session.session_name === selectedSessionName);
    if (!session) {
      console.error('Selected session not found');
      return;
    }
    console.log('API_BASE_URL:', API_BASE_URL);
    const response = await fetch(API_BASE_URL + '/load_session/' + selectedSessionID, {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ session_name: selectedSessionName, language: language }), credentials: 'include'
    });
    if (!response.ok) {
      console.error('Failed to fetch session');
      return;
    }
    session = await response.json() || {};
    if (!session) {
      console.error('No session found');
      return;
    }
    console.log('Session:', session);
    messages.current = session.chatHistory || [WelcomeMessage];
    setPlayAudio(session.playAudio || false);
    setVoiceAllowedToPlay(session.playAudio || false);
    setIsPreDefSessionModalOpen(false);
  };




  const handleSendMessage = async (event) => {
    if (!inputText.trim()) return;

    if (inFlightRequest.current) {
      console.log('Error!!  Request already in flight for adler_session API', 'inputText:', inputText);
      return;
    }

    let updatedText = inputText;
    if (updatedText.includes("VoiceCommand")) {
      updatedText = updatedText.replace("VoiceCommand", "");
    }
    if (debug) console.log(timeWithMilliseconds(), 'Send message called', updatedText, isVoiceListening);
    if (isVoiceListening) {
      return;
    }
    const userMessage = { text: updatedText, isBot: false };
    const body = {
      chatHistory: [...messages.current, userMessage],
      question: updatedText,
      playAudio: String(playAudio ? 1 : 0),
      language: language,
      sessionNumber: sessionNumber,
    };


    const botMessage = { text: '', isBot: true };
    messages.current = [...messages.current, userMessage, botMessage];
    setInputText('');
    scrollToBottom();
    setLastBotMessageIndex(messages.current.length - 1);
    setLastBotMessage(botMessage);

    talkStatusUpdate('sending-message');
    inFlightRequest.current = true;
    if (debug) console.log(timeWithMilliseconds(), 'Fetching adler_session API', updatedText);
    const response = await fetch(API_BASE_URL + '/adler_session', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
      credentials: 'include',
    });
    if (!response.ok) {
      // Handle error
      console.error('Failed to fetch adler_session response');
      inFlightRequest.current = false;
      talkStatusUpdate('failed-sending-message');
      return;
    }


    // Prepare to read the streaming response
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let buffer = '';  // This buffer will accumulate chunks that may contain partial or multiple JSONs
    let lastChunk = {};  // This will store the last valid JSON object
    let accumulatedText = '';  // To accumulate the complete text of the message

    // Find the index of the last bot message to update it
    setLastBotMessageIndex(messages.current.length - 1);

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;  // Exit the loop when the stream is complete

      // Decode the chunk (convert it to a string)
      const chunk = decoder.decode(value, { stream: true });
      
      // Add the current chunk to the buffer
      buffer += chunk;

      // Try to extract valid JSON objects from the buffer
      let boundary = buffer.indexOf('}{');  // Find boundaries between multiple JSON objects
      while (boundary !== -1) {
        // Split at the boundary and extract each JSON object
        const completeJson = buffer.slice(0, boundary + 1); // First valid JSON object
        const remainingBuffer = buffer.slice(boundary + 1); // Remaining part which might contain more JSONs

        try {
          // Parse the complete JSON
          const parsedChunk = JSON.parse(completeJson);
          lastChunk = parsedChunk;
          let audio_session = "";
          // Extract the "text" field from the parsed JSON

          let chunkText = "";
          if ("text" in parsedChunk) {
            chunkText = parsedChunk.text;
            // Append the chunkText to accumulatedText
            accumulatedText += chunkText;
          } else if ("audio_session" in parsedChunk) {
            audio_session = parsedChunk.audio_session;
            if (playAudio) {
              console.log('audio_session:', audio_session, accumulatedText);
              setAudioUrl(audio_session);
              setAudioText(accumulatedText); // Assuming the text returned is the same as played back
              setTrigger(prevTrigger => prevTrigger + 1);
            }
          }

          // Update the last bot message state in real-time
          setLastBotMessage(prevMessage => ({
            ...prevMessage,
            text: accumulatedText
          }));
          //if (debug) console.log(timeWithMilliseconds(), 'Parsed chunk:', chunkText);
          setTrigger(prevTrigger => prevTrigger + 1);

        } catch (error) {
          console.error("Failed to parse chunk as JSON:", completeJson, error);
        }

        // Update the buffer to contain the remaining part
        buffer = remainingBuffer;

        // Look for another boundary in the updated buffer
        boundary = buffer.indexOf('}{');
      }
    }

    // At this point, `buffer` might still contain one complete or partial JSON
    if (buffer.trim()) {
      try {
        // Try to parse any remaining JSON in the buffer
        const parsedChunk = JSON.parse(buffer);
        lastChunk = parsedChunk;
        // Extract the "text" field from the parsed JSON
        const chunkText = parsedChunk.text;
        accumulatedText += chunkText;

        // Update the last bot message state in real-time
        setLastBotMessage(prevMessage => ({
          ...prevMessage,
          text: chunkText
        }));


        // Trigger a re-render for the final update
        setTrigger(prevTrigger => prevTrigger + 1);

      } catch (error) {
        console.error("Failed to parse last chunk as JSON:", buffer, error);
      }
    }
    if (debug) console.log(timeWithMilliseconds(), 'Fetched adler_session API', updatedText);

    const { text, question_name, audio_session, progress } = lastChunk;
    const textClean = text.replace(/^"|"$/g, '');
    const formattedAnswer = textClean.replace(/\\n/g, '\n');
  
    messages.current = messages.current.map((message, index) => {
      if (index === messages.current.length - 1) {
        return { ...message, text: formattedAnswer, question_name: question_name, progress: progress };
      }
      return message;
    });
    if (playAudio) {
      setAudioUrl(audio_session);
      setAudioText(formattedAnswer); // Assuming the text returned is the same as played back
    }

    body.chatHistory.push({ text: textClean, isBot: true, question_name: question_name , progress : progress });


    // need to refresh messages
    setTrigger(prevTrigger => prevTrigger + 1);
    talkStatusClear();
    updatedProgressFunc();
    inFlightRequest.current = false;

    await fetch(API_BASE_URL + '/save_interaction', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
      credentials: 'include',
    });
  };

  useEffect(() => {
    if (inputText.trim() && inputText.includes("VoiceCommand")) {
      if (debug) console.log(timeWithMilliseconds(), 'Voice command detected', inputText);
      setInputText(inputText.replace("VoiceCommand", ""));
      handleSendMessage(new Event('submit'));
    }
  }, [inputText]);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    if (debug) console.log(timeWithMilliseconds(), 'Submit button pressed', inputText);
    if (inputText.trim() !== '') {
      handleSendMessage(inputText); // Call the send message function
      setInputText(''); // Clear the input field
      notifySubmitButtonPressed();
    }
  };

  const notifySubmitButtonPressed = () => {
    if (debug) console.log(timeWithMilliseconds(), 'Notify submit button pressed');
    setSubmitCount((prev) => prev + 1);
  };


  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        // Allow Shift+Enter to create a new line
        return;
      } else {
        e.preventDefault(); // Prevent the default form submission behavior
        handleSubmit(e); // Handle the submit when Enter key is pressed
      }
    }
  };

  let presentationMessages = messages.current;
  if (messages.current.length === 0) {
    presentationMessages = [WelcomeMessage];
  }

  const handleDeleteMessage = async (id) => {
    // Remove the message locally
    // # set messages to all messages except the last two messages
    messages.current = messages.current.slice(0, -2);
    // need to refresh messages
    setTrigger(prevTrigger => prevTrigger + 1);

    // TODO: Implement the logic to delete the message from the server
    const body = {
      id: id,
      chatHistory: messages.current,
      sessionNumber: sessionNumber,
    };
    const response = await fetch(API_BASE_URL + '/delete_message', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
      credentials: 'include',
    });

    // Send the update to the server
    // Example: await fetch(`/api/deleteMessage/${id}`, { method: 'DELETE' });
  };

  const handleEditMessage = async (id) => {
    // Logic to handle editing the message
    console.log(`Edit message with id: ${id}`);
    setInputText(messages.current[id].text);
    handleDeleteMessage(id);
  };


  const handleTextAreaChange = (e) => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    setInputText(e.target.value);

    // Use setTimeout to wait for the state update and re-render
    setTimeout(() => {
      textarea.setSelectionRange(start, end);
    }, 0);
  };

  // Fix the keyboard open state on mobile devices
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleFocus = () => {
      if (isMobile) setIsKeyboardOpen(true);
    };
    const handleBlur = () => {
      if (isMobile) setIsKeyboardOpen(false);
    };

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Add focus and blur event listeners to all input elements
    const inputs = document.querySelectorAll('input, textarea, select');
    inputs.forEach(input => {
      input.addEventListener('focus', handleFocus);
      input.addEventListener('blur', handleBlur);
    });

    // Initial check
    handleResize();

    // Clean up the event listeners on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      inputs.forEach(input => {
        input.removeEventListener('focus', handleFocus);
        input.removeEventListener('blur', handleBlur);
      });
    };
  }, [isMobile]);

  
  // Get the index of the latest user message, if any
  // Get the index of the latest user message, if any

  const userMessages = messages.current.filter(message => !message.isBot);
  const latestUserMessageIndex = userMessages.length > 0 ? messages.current.indexOf(userMessages.slice(-1)[0]) : null;
  return (
    <div className="chat-container">
      <Header
        google_client_id={google_client_id}
        logout={logout}
        setChatLanguage={changeLanguage}
        user_learn_info={user_learn_info}
        loadSession={loadSession}
        debug_info={debug_info}
        showTalkLegendModal={() => setIsLegendModalOpen(true)}
        resetMessages={resetMessages}
      />
      {isMobile && showSideBar && (
          <div className="chat-talk-status-container"  >
            <ChatTalkStatus status={talkStatus}></ChatTalkStatus>
          </div>
      )}
      <div className="chat-messages-container">
        {!isKeyboardOpen && !userLearnInfo &&  (
          <div className={ isMobile ? (showSideBar ? 'pic-area-mobile' : 'pic-area-mobile-fade') : 'pic-area' }>
              {( isMobile && (messages.current.length < 4)) ? <></> : 
                  <img className="adler-image" src="adler.png" alt="Adler Picture" />
              }
              { (messages.current.length > 2) && showProgressBar ? (
                <div className="progress-bar-container" > 
                  <VerticalProgressBar steps={updatedProgress} />
                </div>
              ) : <> </> }
              { !isMobile && showTalkStatus && 
                <div className="chat-talk-status-container"  >
                  <ChatTalkStatus status={talkStatus}></ChatTalkStatus>
                </div>
              }
              <div className="chat-tal-legend-container" >
                {showTalkLegend.current &&
                  <ChatTalkLegend className="chat-legend" ></ChatTalkLegend>}
              </div>    
          </div>
        )}
        {debugInfo ? (
          <DebugInformation debug_info={debug_info} sessionNumber={sessionNumber} />
        ) : userLearnInfo ? (
          <UserLearnedInformation user_learn_info={user_learn_info} sessionNumber={sessionNumber}/>
        ) : (
          <div className='messages-and-control'>
            <div className="chat-messages">
              {presentationMessages.map((message, index) => (
                <ChatMessage
                  key={index}
                  message={(index === lastBotMessageIndex && index > 2) ? lastBotMessage : message} // Render last message with dedicated state
                  onDelete={handleDeleteMessage}
                  onEdit={handleEditMessage}
                  isLatestUserMessage={index === latestUserMessageIndex}
                  index={index}
                />
              ))}
              <div ref={messagesEndRef} />
            </div>
            <div className="chat-controls">
              <div className="chat-buttons">
                <TalkInterface
                  voiceRecordingFinish={voiceRecordingFinish}
                  getLanguage={getLanguage}
                  voiceFinalTranscriptUpdate={voiceFinalTranscriptUpdate}
                  voiceListeningStopped={voiceListeningStopped}
                  getCurrentText={getCurrentInputText}
                  audioUrl={audioUrl}
                  audioText={audioText}
                  voiceAllowedToPlay={voiceAllowedToPlay}
                  updateShowTalkLegend={updateShowTalkLegend}
                  clearAudioURL={clearAudioURL}
                  talkStatusUpdate={talkStatusUpdate}
                  inFlightRequest={inFlightRequest}
                  submitCount={submitCount}
                />
                <button
                  onClick={togglePlayAudio}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  className={`play-button ${playAudio ? 'playing' : 'off'}`}
                >
                  {hover ? (playAudio ? t('chat.voiceOnHover') : t('chat.voiceOffHover')) : (playAudio ? t('chat.voiceOn') : t('chat.voiceOff'))}
                </button>
               
              </div>
              <form className="chat-input" onSubmit={handleSubmit}>
                <textarea
                  type="text"
                  placeholder={t('chat.typeHere')}
                  value={inputText}
                  onChange={handleTextAreaChange}
                  onKeyDown={handleKeyDown}
                  ref={textareaRef}
                  dir={language === 'he-IL' ? 'rtl' : 'ltr'}
                  rows="3"
                />
                <button type="submit" className="send-button">
                  <img src="send.png" alt="Send" />
                </button>
              </form>
              <Modal
                isOpen={isPreDefSessionModalOpen}
                onRequestClose={() => setIsPreDefSessionModalOpen(false)}
                className={'predefined-session-modal'}
              >
                <h2>{t('chat.predefinedSession')}</h2>
                <select value={selectedSessionName} onChange={e => setSelectedSessionName(e.target.value)}>
                  <option value="">{t('chat.sessions')}</option>
                  {sessionNames.map(name => (
                    <option key={name} value={name}>{name}</option>
                  ))}
                </select>
                <button onClick={() => loadPredifendSession()} style={{ margin: '10px', backgroundColor: 'blue' }}>
                  {t('chat.select')}
                </button>
                <button onClick={() => setIsPreDefSessionModalOpen(false)} style={{ margin: '10px', backgroundColor: 'gray' }}>
                  {t('chat.cancel')}
                </button>
              </Modal>
              {/* <Modal
                isOpen={isLegendModalOpen}
                onRequestClose={() => setIsLegendModalOpen(false)}
                className={'legend-modal'}
              >
                <ChatTalkLegend />
                <button onClick={() => setIsLegendModalOpen(false)} style={{ margin: '10px', backgroundColor: 'gray' }}>
                  {t('chat.close')}
                </button>
              </Modal>  */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default ChatInterface;

